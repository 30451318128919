import React, { useContext } from "react";
import ReactDOM from "react-dom/client";

// global style
import GlobalStyle from "./components/styles/Global.styles";

// react router
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// react query - caching and fetching
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

// public pages
import Homepage from "./pages/public/Login";
// import ForgotPassword from "./pages/public/ForgotPassword";
// import Register from "./pages/public/Register";

// private pages
import Dashboard from "./pages/private/Dashboard";
import Orders from "./pages/private/Orders";
import Refunds from "./pages/private/Refunds";
// import Users from "./pages/private/Users";
// import Projects from "./page/private/Projects";
// import Profile from "./pages/private/Profile";

// universal pages
import InProggress from "./pages/public/InProggress";

// global component
import { Container } from "./components/styles/Global.styles";
import DashboardSidebar from "./components/shared/DashboardSidebar";

// context API
import AuthContextProvider, { AuthContext } from "./contexts/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
    },
  },
});

const Public = () => {
  return (
    <Routes>
      <Route path="/login" element={<Homepage />} />
      <Route path="/register" element={<InProggress />} />
      <Route path="/forgot-password" element={<InProggress />} />
      <Route path="/*" element={<Navigate to="/login" replace />} />
      <Route path="*" element={<InProggress />} />
    </Routes>
  );
};

const Private = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/refunds" element={<Refunds />} />
      <Route path="/profile" element={<InProggress />} />
      <Route path="/users" element={<InProggress />} />
      <Route path="/projects" element={<InProggress />} />
      <Route path="/*" element={<Navigate to="/dashboard" replace />} />
      <Route path="*" element={<InProggress />} />
    </Routes>
  );
};

const App = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Container>
      <BrowserRouter>
        {isLoggedIn === true && <DashboardSidebar />}
        {isLoggedIn === true && <Private />}
        {isLoggedIn === false && <Public />}
      </BrowserRouter>
    </Container>
  );
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <GlobalStyle />
        <App />
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
