import React from "react";
import DashboardCharts from "../../components/shared/DashboardCharts";

// styles
import { Holder } from "../../components/styles/Dashboard.styles";

const Dashboard = () => {
  return (
    <Holder>
      <DashboardCharts />
    </Holder>
  );
};

export default Dashboard;
