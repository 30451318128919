import React from "react";
import {
  LoaderContainer,
  SpinnerUl,
  SpinnerLi,
} from "../../styles/Loader.styles";

function Loader() {
  return (
    <LoaderContainer>
      <SpinnerUl>
        <SpinnerLi></SpinnerLi>
        <SpinnerLi></SpinnerLi>
        <SpinnerLi></SpinnerLi>
        <SpinnerLi></SpinnerLi>
        <SpinnerLi></SpinnerLi>
        <SpinnerLi></SpinnerLi>
        <SpinnerLi></SpinnerLi>
        <SpinnerLi></SpinnerLi>
        <SpinnerLi></SpinnerLi>
      </SpinnerUl>
    </LoaderContainer>
  );
}

export default Loader;
