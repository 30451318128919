import React from "react";

// components
import {
  MainDashboardContainer,
  MainChartWrapper,
  SubChartWrapper,
  ChartHolder,
  TopRow,
  BottomRow,
} from "../../components/styles/DashboardCharts.styles";
import DoughnutChart from "./charts/DoughnutChart";
import BarChart from "./charts/BarChart";
import AreaChart from "./charts/AreaChart";

function DashboardCharts() {
  return (
    <MainDashboardContainer>
      <ChartHolder>
        <TopRow>
          <MainChartWrapper>
            <AreaChart />
          </MainChartWrapper>
        </TopRow>
        <BottomRow>
          <SubChartWrapper>
            <BarChart chartName="Orders/refunds ratio / in USD" />
          </SubChartWrapper>
          <SubChartWrapper>
            <BarChart chartName="Orders/refunds ratio / in purchases" />
          </SubChartWrapper>
          <SubChartWrapper>
            <DoughnutChart />
          </SubChartWrapper>
        </BottomRow>
      </ChartHolder>
    </MainDashboardContainer>
  );
}

export default DashboardCharts;
