import styled from "styled-components";

export const MainDashboardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;

export const ChartHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1924px;
  height: 90%;
  border-radius: 20px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px 80px 20px 80px;

  @media (max-width: 1660px) {
    margin: 20px;
  }
`;

export const ParentChartWrapper = styled.div`
  display: flex;
`;

export const TopRow = styled(ParentChartWrapper)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const BottomRow = styled(ParentChartWrapper)`
  flex: 1;
  align-items: center;
`;

export const MainChartWrapper = styled(ParentChartWrapper)`
  max-width: 100%;
  width: 90%;
  height: 95%;
  justify-content: center;
  align-items: center;
`;

export const SubChartWrapper = styled(ParentChartWrapper)`
  flex: 1;
  height: 90%;
  justify-content: center;
  align-items: center;
`;
