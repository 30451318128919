import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

// api functions
import { getOrders } from "../../api/service";

// data grid util
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";

// components
import Loader from "./loaders/Loader";
import Selector from "../Selector";

// this is table head - static

const columns = [
  { key: "id", name: "id" },
  { key: "time", name: "time" },
  { key: "order_reference", name: "order reference", width: 220 },
  { key: "number_of_licenses", name: "license number" },
  { key: "OCR_addon", name: "OCR" },
  { key: "country", name: "country" },
  { key: "order_value", name: "order value" },
  { key: "currency", name: "currency" },
  { key: "source", name: "source_purchased", width: 200 },
  { key: "utm_source", name: "utm_source" },
  { key: "utm_medium", name: "utm_medium" },
  { key: "utm_content", name: "utm_content" },
  { key: "utm_term", name: "utm_term" },
  { key: "utm_campaign", name: "utm_campaign", width: 150 },
  { key: "source_trial", name: "trial" },
  { key: "user_id", name: "user_id", width: 150 },
  { key: "trial_utm_source", name: "trial_utm_source", width: 150 },
  { key: "trial_utm_medium", name: "trial_utm_medium", width: 150 },
  { key: "trial_utm_content", name: "trial_utm_content", width: 150 },
  { key: "trial_utm_term", name: "trial_utm_term", width: 150 },
  { key: "trial_utm_campaign", name: "trial_utm_campaign", width: 150 },
  { key: "trial_source", name: "source_trial", width: 150 },
];

function DataTableOrders({ start, end, token, store }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["orders", start, end, token, store],
    queryFn: () => getOrders(start, end, token, store),
    enabled: isEnabled,
  });

  useEffect(() => {
    refetch();
  }, [store, refetch]);

  if (error) return <div>Request Failed</div>;
  if (isLoading) {
    setTimeout(() => setIsEnabled(true), 1000);
    return <Loader />;
  }

  const dataDisplay = data.data;

  console.log("Our data:", dataDisplay);
  return (
    <>
      <Selector />
      <br></br>
      <DataGrid
        enableVirtualization={true}
        className="datagrid"
        columns={columns}
        rows={dataDisplay}
      />
    </>
  );
}

export default DataTableOrders;
