import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

// api functions
import { getRefunds } from "../../api/service";

// data grid util
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";

// components
import Loader from "./loaders/Loader";

// this is table head - static

const columns = [
  { key: "order_reference", name: "order reference" },
  { key: "time", name: "time" },
  { key: "total_return", name: "total return" },
  { key: "currency", name: "currency" },
  { key: "country", name: "country" },
  { key: "OCR_addon", name: "OCR" },
  { key: "source", name: "source" },
  { key: "utm_source", name: "utm_source" },
  { key: "utm_medium", name: "utm_medium" },
  { key: "utm_content", name: "utm_content" },
  { key: "utm_term", name: "utm_term" },
  { key: "utm_campaign", name: "utm_campaign" },
  { key: "user_id", name: "user_id" },
  { key: "trial_utm_source", name: "trial_utm_source" },
  { key: "trial_utm_medium", name: "trial_utm_medium" },
  { key: "trial_utm_content", name: "trial_utm_content" },
  { key: "trial_utm_term", name: "trial_utm_term" },
  { key: "trial_utm_campaign", name: "trial_utm_campaign" },
];

function DataTableRefunds({ start, end, token }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const { data, error, isLoading } = useQuery({
    queryKey: ["refunds", start, end, token],
    queryFn: () => getRefunds(start, end, token),
    enabled: isEnabled,
  });

  if (error) return <div>Request Failed</div>;

  if (isLoading) {
    setTimeout(() => setIsEnabled(true), 1000);
    return <Loader />;
  }

  const dataDisplay = data.data;

  return (
    <DataGrid
      enableVirtualization={true}
      className="datagrid"
      columns={columns}
      rows={dataDisplay}
    />
  );
}

export default DataTableRefunds;
