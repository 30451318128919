import styled from "styled-components";
import { Input } from "../../components/styles/Forms.styles";

export const ExportContainer = styled.div`
  display: flex;
  min-height: 20%;
  gap: 40px;
  justify-content: center;
  align-items: center;
`;

export const ExportInput = styled(Input)`
  border: 1px solid gainsboro;
  color: black;
  max-width: 300px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.7);
  }
`;

export const ExportLabel = styled.label`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
  margin-right: -20px;
`;
