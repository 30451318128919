import React, { useContext } from "react";
import { useMutation } from "@tanstack/react-query";

import {
  AnchorLink,
  Errors,
  FormContainer,
  FormTitle,
  Icon,
  Input,
  Label,
  Submit,
  InputHolder,
} from "../styles/Forms.styles";
import { useForm } from "react-hook-form";
import { Login } from "../../api/service";
import { AuthContext } from "../../contexts/AuthContext";

function LoginForm() {
  // context API
  const { setIsLoggedIn, setToken, setUser } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const handleLogin = (data) => {
    clearErrors(["email", "password"]);
    mutation.mutate({ data });
  };
  const handleError = (errors) => {
    console.log(errors);
  };

  const loginOptions = {
    email: {
      required: "Email is required",
      pattern: {
        value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        message: "Email must contain @ symbol. Please enter valid email!",
      },
    },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message:
          "Password must contain at least one number, one uppercase and lowercase letter and one symbol. Also it has to have at least 8 or more characters",
      },
      pattern: {
        value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        message:
          "Password must contain at least one number, one uppercase and lowercase letter and one symbol. Also it has to have at least 8 or more characters",
      },
    },
  };

  const mutation = useMutation({
    mutationFn: Login,
    onError: (error) => {
      // An error happened!
      alert("Wrong credentials!");
    },
    onSuccess: (res) => {
      localStorage.setItem("user", JSON.stringify(res));
      setToken(res.token);
      setUser(res);
      setIsLoggedIn(true);
    },
  });

  return (
    <FormContainer>
      <Icon src="http://icons.iconarchive.com/icons/hopstarter/face-avatars/128/Male-Face-O1-icon.png" />
      <FormTitle>Log In Here</FormTitle>
      <form noValidate onSubmit={handleSubmit(handleLogin, handleError)}>
        <Label htmlFor="email">Email</Label>
        <InputHolder>
          <Input
            type="email"
            name="email"
            placeholder="Enter Email"
            aria-invalid={errors.email ? "true" : "false"}
            {...register("email", loginOptions.email)}
          />
          {errors.email ? (
            <Errors role="alert">
              {errors?.email && errors?.email?.message}
            </Errors>
          ) : null}
        </InputHolder>
        <Label htmlFor="password">Password</Label>
        <InputHolder>
          <Input
            type="password"
            name="password"
            placeholder="Enter Password"
            aria-invalid={errors.password ? "true" : "false"}
            {...register("password", loginOptions.password)}
          />
          {errors.password && !errors.email ? (
            <Errors role="alert">
              {errors?.password && errors?.password?.message}
            </Errors>
          ) : null}
        </InputHolder>
        <Submit type="submit" name="sign-in" value="Sign In" />
        <AnchorLink to="/forgot-password">Forgot Password?</AnchorLink>
      </form>
    </FormContainer>
  );
}

export default LoginForm;
