import React from "react";
import { useState, useRef, useContext } from "react";

// api functions
import { downloadOrdersCSV } from "../../api/service";

// components
import { Holder } from "../../components/styles/Dashboard.styles";
import {
  MainDashboardContainer,
  ChartHolder,
} from "../../components/styles/DashboardCharts.styles";
import { SubmitButton } from "../../components/styles/Buttons.styles";
import {
  ExportContainer,
  ExportInput,
  ExportLabel,
} from "../../components/styles/Export.styles";
import DataTableOrders from "../../components/shared/DataTableOrder";

// context API
import { AuthContext } from "../../contexts/AuthContext";

function Orders() {
  // context API
  const { token, store } = useContext(AuthContext);

  const startInputRef = useRef();
  const endInputRef = useRef();
  const fileNameRef = useRef();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  function handleSubmit() {
    setStart(startInputRef.current.value);
    setEnd(endInputRef.current.value);
  }

  function handleExport() {
    downloadOrdersCSV(
      startInputRef.current.value,
      endInputRef.current.value,
      fileNameRef.current.value,
      token
    );
  }

  return (
    <Holder>
      <MainDashboardContainer>
        <ChartHolder>
          <DataTableOrders
            start={start}
            end={end}
            token={token}
            store={store}
          />
          <ExportContainer>
            <ExportLabel htmlFor="from">From: </ExportLabel>
            <ExportInput
              type="text"
              name="from"
              ref={startInputRef}
              placeholder="e.g. 2022-12-01"
            />
            <ExportLabel htmlFor="to">To: </ExportLabel>
            <ExportInput
              type="text"
              name="to"
              ref={endInputRef}
              placeholder="e.g. 2022-12-15"
            />
            <ExportLabel htmlFor="fileName">File name: </ExportLabel>
            <ExportInput
              type="text"
              ref={fileNameRef}
              name="fileName"
              placeholder="e.g. OrdersReport"
            />
          </ExportContainer>

          <SubmitButton type="submit" onClick={() => handleSubmit()}>
            Submit
          </SubmitButton>
          <SubmitButton type="submit" onClick={() => handleExport()}>
            Export CSV file
          </SubmitButton>
        </ChartHolder>
      </MainDashboardContainer>
    </Holder>
  );
}

export default Orders;
