import React, { useState, useEffect } from "react";
import { createContext } from "react";

export const AuthContext = createContext(null);

const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [token, setToken] = useState("");
  const [user, setUser] = useState({});
  const [store, setStore] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setToken(JSON.parse(localStorage.getItem("user")).token);
      setUser(JSON.parse(localStorage.getItem("user")));
      setIsLoggedIn(true);
    } else {
      setToken("");
      setUser({});
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        token,
        setToken,
        user,
        setUser,
        store,
        setStore,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
