import React from "react";
import styled from "styled-components";

// image
import Img from "../images/backgroundImg.jpg";

const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${Img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function BackgroundImg({ children }) {
  return <Background>{children}</Background>;
}

export default BackgroundImg;
