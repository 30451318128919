import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }

  :root {
  /* Colors */
  --black: #09090c;
  --grey: #a4b2bc;
  --white: #fff;
  --background: rgba(137, 171, 245, 0.37);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.datagrid{
  max-height:49%;
  height:max-content;
  border-radius:5px;
  text-align:center;
}


`;

export default GlobalStyle;

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: auto;
`;
