import React, { useContext } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// Global context
import { AuthContext } from "../contexts/AuthContext";

export default function Selector() {
  const { store, setStore } = useContext(AuthContext);

  React.useEffect(() => {
    console.log(store);
  }, [store]);

  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={store || "PDF Pro"}
          onChange={(event) => setStore(event.target.value)}
        >
          <MenuItem value={"PDF Pro"}>PDF Pro</MenuItem>
          <MenuItem value={"PDF Pro 10"}>PDF Pro 10</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
