import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function InProggress() {
  return (
    <Container>
      <h1>We are sorry but this page is not ready yet!</h1>
      <br />
      <h2>
        There is still work in proggress for this page. It will be available
        soon!
      </h2>
      <br />
      <h3>Sorry for the inconvinience!</h3>
      <br />
      <Link to="/">Go back</Link>
    </Container>
  );
}

export default InProggress;
