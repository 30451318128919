import styled from "styled-components";

export const LoaderContainer = styled.div`
  display: flex;
  height: 49%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

export const SpinnerUl = styled.ul`
  width: 108px;
  height: 108px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
`;

export const SpinnerLi = styled.li`
  width: 30px;
  height: 30px;
  margin: 2px;
  background-color: transparent;
  animation: loading 0.8s infinite;

  &:nth-child(5) {
    opacity: 0;
  }
  &:nth-child(1) {
    animation-delay: 0.1s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.3s;
  }
  &:nth-child(6) {
    animation-delay: 0.4s;
  }
  &:nth-child(9) {
    animation-delay: 0.5s;
  }
  &:nth-child(8) {
    animation-delay: 0.6s;
  }
  &:nth-child(7) {
    animation-delay: 0.7s;
  }
  &:nth-child(4) {
    animation-delay: 0.8s;
  }

  @keyframes loading {
    1% {
      background-color: #0086b3;
    }
  }
`;
