import React from "react";

// components
import BackgroundImg from "../../components/BackgroundImg";
import LoginForm from "../../components/shared/LoginForm";

function Login() {
  return (
    <BackgroundImg>
      <LoginForm />
    </BackgroundImg>
  );
}

export default Login;
