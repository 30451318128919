import styled from "styled-components";

// internal routing - Link
import { Link } from "react-router-dom";

export const FormContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 420px;
  padding: 70px 40px;
  border-radius: 20px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.8);
`;

export const Icon = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: calc(-100px / 2);
  left: calc(50% - 50px);
`;

export const FormTitle = styled.h2`
  margin: 0;
  padding: 0 0 26px;
  color: #00a8a8;
  text-align: center;
`;

const ParentInput = styled.input`
  width: 100%;
  margin-bottom: 20px;
  padding-left: 10px;
`;

export const Input = styled(ParentInput)`
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  outline: none;
  height: 40px;
  color: #fff;
  font-size: 16px;
  padding-left: 10px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const Submit = styled(ParentInput)`
  border: none;
  outline: none;
  height: 40px;
  color: #eee;
  font-size: 16px;
  background-color: #00a8a8;
  font-weight: bolder;
  cursor: pointer;
  border-radius: 20px;
  margin: 12px 0 18px;

  &:hover {
    background-color: #40e0df;
    color: #fff;
  }
`;

export const AnchorLink = styled(Link)`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
`;

export const Label = styled.label`
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #fff;
`;

export const Errors = styled.small`
  position: absolute;
  left: 0%;
  top: 100%;
  width: max-content;
  max-width: 100%;
  background-color: #fff;
  padding: 16px 18px;
  font-size: 20px;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 5;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 24px; // offset should move with padding of parent
    border: 12px solid transparent;
    border-top: none;
    border-bottom-color: #fff;
    filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.1));
    pointer-events: none;
    z-index: 5;
  }
`;

// you added this styled component because of the position relative in Errors component

export const InputHolder = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`;
