import styled from "styled-components";

export const SubmitButton = styled.button`
  border: none;
  outline: none;
  height: 40px;
  color: #eee;
  font-size: 16px;
  background-color: #00a8a8;
  font-weight: bolder;
  cursor: pointer;
  border-radius: 20px;
  margin: 12px 0 18px;

  &:hover {
    background-color: #40e0df;
    color: #fff;
  }
`;
