import React from "react";
import { useState, useRef, useContext } from "react";

// api functions
import { downloadRefundsCSV } from "../../api/service";

// components
import { Holder } from "../../components/styles/Dashboard.styles";
import {
  MainDashboardContainer,
  ChartHolder,
} from "../../components/styles/DashboardCharts.styles";
import { SubmitButton } from "../../components/styles/Buttons.styles";
import {
  ExportContainer,
  ExportInput,
  ExportLabel,
} from "../../components/styles/Export.styles";
import DataTable from "../../components/shared/DataTableRefunds";

// context API
import { AuthContext } from "../../contexts/AuthContext";

function Refunds() {
  const startInputRef = useRef();
  const endInputRef = useRef();
  const fileNameRef = useRef();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  // context API
  const { token } = useContext(AuthContext);

  function handleSubmit() {
    setStart(startInputRef.current.value);
    setEnd(endInputRef.current.value);
  }

  function handleExport() {
    downloadRefundsCSV(
      startInputRef.current.value,
      endInputRef.current.value,
      fileNameRef.current.value,
      token
    );
  }

  return (
    <Holder>
      <MainDashboardContainer>
        <ChartHolder>
          <DataTable start={start} end={end} token={token} />
          <ExportContainer>
            <ExportLabel htmlFor="from">From: </ExportLabel>
            <ExportInput
              type="text"
              ref={startInputRef}
              name="from"
              placeholder="e.g. 2022-12-01"
            />
            <ExportLabel htmlFor="to">To: </ExportLabel>
            <ExportInput
              type="text"
              ref={endInputRef}
              name="to"
              placeholder="e.g. 2022-12-15"
            />
            <ExportLabel htmlFor="fileName">File name: </ExportLabel>
            <ExportInput
              type="text"
              ref={fileNameRef}
              name="fileName"
              placeholder="e.g. RefundsReport"
            />
          </ExportContainer>

          <SubmitButton type="submit" onClick={() => handleSubmit()}>
            Submit
          </SubmitButton>
          <SubmitButton type="button" onClick={() => handleExport()}>
            Export CSV file
          </SubmitButton>
        </ChartHolder>
      </MainDashboardContainer>
    </Holder>
  );
}

export default Refunds;
